import { version } from '../../package.json';

export type Config = {
    VERSION: string;
    ENABLE_ACTIVITY_UPDATE: boolean;
    ENABLE_VOYAGE_CREATE: boolean;
    ENABLE_VOYAGE_UPDATE: boolean;
    ENFORCE_ACTIVITY_ORDER: boolean;
}

const defaults: Config = {
    VERSION: version,
    ENABLE_ACTIVITY_UPDATE: true,
    ENABLE_VOYAGE_CREATE: true,
    ENABLE_VOYAGE_UPDATE: true,
    ENFORCE_ACTIVITY_ORDER: false
};

export async function fetchConfig(): Promise<Config> {
    try {
        const config: Partial<Config> = await fetch('/config.json')
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }

                throw new Error(res.statusText);
            });

       return Object.assign(defaults, config);
    } catch(err) {
        console.warn('Could not load config. Using defaults');

        return defaults;
    }
}
