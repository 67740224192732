import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';

import { ActivityInRibbon } from '../models/ActivityInRibbon';

import { BehaviorSubject } from 'rxjs';
import { ActivityLogService } from "./ActivityLogService";
import { Activity } from "../models/Activity";

import type { Config } from '../config';

export interface SwitchActivityResponse extends Response{
    readonly data: {
        id: string
    }
}

export class ActivityService {
    static $inject = ['$http', 'activityLogService', 'config'];

    private activityServiceUrl = '/ship/rest/activity';

    private activities: ActivityInRibbon[];

    private startingVoyage: Boolean;

    allowedActivities: BehaviorSubject<ActivityInRibbon[]>;

    private currentActivity: Activity;

    constructor(private $http, readonly activityLogService: ActivityLogService, readonly config: Config) {
        this.allowedActivities = new BehaviorSubject([]);
        this.startingVoyage = false;
        this.activityLogService.getCurrentActivity().then((current) => {
            this.currentActivity = current;
        });

        this.activityLogService.subscribe(this.checkIfUpdatesAreNeeded);
    }

    getActivities(): void {
        this.$http.get(this.activityServiceUrl)
            .then((response) => {
                this.activities = orderBy(filter(response.data, { active: true }), ['order']);
            }).catch((err) => {
                console.log(`Failed to get activities; ${JSON.stringify(err)}`)
            });
    }

    switchActivity(activity: ActivityInRibbon, jobId?: string): angular.IPromise<any> {
        const payload = {
            activityId: activity.id,
            jobId: jobId,
        };
        return this.$http.post(this.activityServiceUrl, payload);
    }

    updateRemark(id: string, remark: string): angular.IPromise<any> {
        return this.$http.put(this.activityServiceUrl + '/' + id + '/remark',
            remark,
        );
    }

    updateEquipment(id: string, equipment: any): angular.IPromise<any> {
        return this.$http.put(this.activityServiceUrl + '/' + id + '/equipment',
            equipment,
        );
    }

    private checkIfUpdatesAreNeeded = (currentActivityFromServer: Activity | null) => {
        if (!currentActivityFromServer) {
            return;
        }

        const activityIsChanged = () => {
            if (this.currentActivity && this.currentActivity.id) {
                return this.currentActivity.id !== currentActivityFromServer.id;
            }

            return !this.currentActivity && currentActivityFromServer;
        };

        if (activityIsChanged()) {
            this.currentActivity = currentActivityFromServer;
            this.updateAllowedActivities(currentActivityFromServer);
        }
    };

    updateAllowedActivities(currentActivity: Activity): void {
        // TODO the code below hardcodes certain restrictions on the allowed activities for Boluda ships.
        //   If more clients need these restrictions, this code should be made generalisable, through a config
        //   file, s.t. you can specify per ship which restrictions apply.
        if (!this.config.ENFORCE_ACTIVITY_ORDER) {
            this.allowedActivities.next(this.activities);
        } else if (this.startingVoyage && !currentActivity) {
            this.allowedActivities.next([
                this.activities.find((a) => a.name === "Called by dispatch")
            ]);
        } else if (!currentActivity) {
            this.allowedActivities.next(this.activities);
        } else if (currentActivity.activityName === "Called by dispatch") {
            this.allowedActivities.next([
                this.activities.find((a) => a.name === "Mobilisation")
            ]);
        } else if (currentActivity.activityName === "Mobilisation") {
            this.allowedActivities.next([
                this.activities.find((a) => a.name === "On Site")
            ]);
        } else if (currentActivity.activityName === "On Site") {
            this.allowedActivities.next(
                this.activities.filter((a) => ["Line to Stern", "Line to Bow", "Pushing to Berth", "Fire Watch", "Standby"].includes(a.name))
            );
        } else if (currentActivity.activityName === "Line to Stern" || currentActivity.activityName === "Line to Bow") {
            this.allowedActivities.next(
                this.activities.filter((a) => ["Pushing to Berth", "Fire Watch", "Job to Job", "Sail to berth"].includes(a.name))
            );
        } else if (currentActivity.activityName === "Pushing to Berth" || currentActivity.activityName === "Standby" || currentActivity.activityName === "Fire Watch") {
            this.allowedActivities.next(
                this.activities.filter((a) => ["Job to Job", "Sail to berth"].includes(a.name))
            );
        } else if (this.startingVoyage) {
            this.allowedActivities.next([
                this.activities.find((a) => a.name === "Called by dispatch")
            ]);
        } else {
            this.allowedActivities.next(this.activities);
        }
    }

    setStartingVoyage(startingVoyage: Boolean): void {
        this.startingVoyage = startingVoyage;
        this.updateAllowedActivities(this.currentActivity);
    }
}
